import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { TollTipButton, TollTipContent } from './styles';
import { Text } from '../common';

export const ToolTip = ({ text }: {text: string}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isAcitve, setActive] = useState({
    top: 0,
    left: 0,
  });

  const onActive = () => {
    const top = ref.current?.getBoundingClientRect().top || 0;
    const left = ref.current?.getBoundingClientRect().left || 0;
    setActive({ top: top - 60, left });
  };

  const onHide = () => {
    setActive({ left: 0, top: 0 });
  };

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!ref.current || ref.current.contains(e.target as Node)) {
        return;
      }
      onHide();
    };
    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  return (
    <>
      <TollTipButton ref={ref} onClick={onActive}>
        {!!isAcitve?.left && !!isAcitve?.top && createPortal(<TollTipContent style={{ top: isAcitve.top, left: isAcitve.left }}><Text>{text}</Text></TollTipContent>, document.body) }
      </TollTipButton>
    </>
  );
};
